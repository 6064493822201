import {createSlice} from '@reduxjs/toolkit'

export const phoneSlice = createSlice({
    name: 'phone',
    initialState: {
        phones: {
            'c230': 0,
            'c250': 0,
            'c270': 2,
            'sm100m10': 0,
            'sm100m10r': 0,
            'sm10': 0,
            'sm10r': 0,
            'sc520': 0,
            'soft': 0
        },
        adapters: {
            'c230': false,
            'c250': false,
            'c270': false,
            'sm100m10': false,
            'sm100m10r': false,
            'sm10': false,
            'sm10r': false,
            'sc520': false,
            'soft': false
        }
    },
    reducers: {
        removePhone: (state, action) => {
            const {payload: {model}} = action;
            state.phones[model] = 0;
        },
        changePhone: (state, action) => {
            const {payload: {oldModel, newModel}} = action;
            const qty = state.phones[oldModel];
            state.phones[newModel] = qty;
            state.phones[oldModel] = 0;
        },
        updateAmt: (state, action) => {
            const {payload: {model, val}} = action;
            state.phones[model] = Number(val);
        },
        setAdapter: (state, action) => {
            const {payload: {model, include}} = action;
            state.adapters[model] = include;
        }
    },
})

export const {removePhone, updateAmt, changePhone, setAdapter} = phoneSlice.actions

export default phoneSlice.reducer
