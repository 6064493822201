import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {removePhone, updateAmt, changePhone, setAdapter} from './phonesSlice';
import c230Img from '../images/phones/Crosstalk_230_blue_angle_1200_web.jpg';
import c250Img from '../images/phones/Crosstalk_250_blue_angle_1200_web.jpg';
import c270Img from '../images/phones/Crosstalk_270_blue_angle_1200_web.jpg';
import sm100m10Img from '../images/phones/snom-m10kle-m100kle-500x500.png';
import sm100m10rImg from '../images/phones/snom-m10rkle-m100kle-500x500.png';
import sm10Img from '../images/phones/snom-m10kle-500x500.png';
import sm10rImg from '../images/phones/snom-m10rkle-500x500.png';
import sc520Img from '../images/phones/snom-c520-500x500.png';
import softImg from '../images/phones/softphone.png';

const imgMapper = {
    'c230': c230Img,
    'c250': c250Img,
    'c270': c270Img,
    'sm100m10': sm100m10Img,
    'sm100m10r': sm100m10rImg,
    'sm10': sm10Img,
    'sm10r': sm10rImg,
    'sc520': sc520Img,
    'soft': softImg
};

const models = {
    'c230': 'Crosstalk 230',
    'c250': 'Crosstalk 250',
    'c270': 'Crosstalk 270',
    'sm100m10': 'Snom M100 KLE + M10 KLE (1 standard handset + DECT base)',
    'sm100m10r': 'Snom M100 KLE + M10R KLE (1 ruggedized handset + DECT base)',
    'sm10': 'Snom M10 KLE (Standard DECT handset only)',
    'sm10r': 'Snom M10R KLE (Ruggedized DECT handset only)',
    'sc520': 'Snom C520 Conference Phone',
    'soft': 'Softphone Only User'
};

const modelDescriptions = {
    'c230': 'The Crosstalk 230 is a great entry level phone for standard users. It features 4 programmable menu keys, Gigabit Ethernet passthrough port, and is wall mountable without a separate bracket.',
    'c250': 'The Crosstalk 250 is a powerful phone for standard users. It features 8 programmable menu keys, a full color display, and a Gigabit Ethernet passthrough port.',
    'c270': 'The Crosstalk 270 is a powerful phone for receptionists and power users. It features 10 programmable line keys, Gigabit Ethernet passthrough port, and comes standard with WiFi and Bluetooth built in.',
    'sm100m10': 'The Snom M100 KLE + M10 KLE is our go-to DECT solution for small to medium businesses.  This kit comes with (1) M100 KLE DECT base and (1) M10 KLE cordless handset.  The DECT base can handle up to (10) M10 KLE or M10R KLE cordless handsets and can process up to (6) calls simultaneously.  Comes with a standard 3-year warranty.',
    'sm100m10r': 'The Snom M100 KLE + M10R KLE is a DECT solution with a ruggedized cordless handset for small to medium businesses who need shock and water resistant features for demanding environments.  This kit comes with (1) M100 KLE DECT base and (1) M10R KLE ruggedized cordless handset.  The DECT base can handle up to (10) M10 KLE or M10R KLE cordless handsets and can process up to (6) calls simultaneously.  Comes with a standard 3-year warranty.',
    'sm10': 'The Snom M10 KLE is an add-on cordless handset for the Snom M100 KLE DECT wireless base.  Up to 10 of these handsets can be added to a single M100 KLE DECT base.  Comes with a standard 3-year warranty.',
    'sm10r': 'The Snom M10R KLE is a ruggedized add-on cordless handset for the Snom M100 KLE DECT wireless base.  The Snom M10R KLE has all of the same features as the M10 KLE handset, but is additionally shock and water resistant, and also has a locking battery compartment.  This handset is meant to be used in environments where the cordless handset may be susceptible to water/rain or may get knocked around/dropped frequently.  Up to 10 of these handsets can be added to a single M100 KLE DECT base.  Comes standard with a 2-year warranty.  Comes with a standard 3-year warranty.',
    'sc520': 'The Snom C520 is a high quality conference phone for small to medium sized conference rooms.  It features a full duplex speakerphone and two removable wireless microphones in order to pick up audio from anywhere in the room.  Comes with a standard 2-year warranty.',
    'soft': 'Softphone Only Users can use the Clearly Anywhere mobile softphone for Android and iOS devices, or the Crosstalk Cloud Call Panel softphone on desktop.'
};

export default function PhoneSelect({phone, availableModels, deleteFn, changeFn}) {
    const [currentModel, setModel] = useState(phone.model);
    const [qty, setQty] = useState(Number(phone.qty).toFixed(0));
    const [popover, setPopover] = useState(false);
    const dispatch = useDispatch();

    const deleteSelect = () => {
        dispatch(removePhone({model: currentModel}));
        deleteFn(currentModel);
    };

    const changeModel = (e) => {
        const newModel = e?.target?.value;
        if (newModel) {
            dispatch(changePhone({oldModel: currentModel, newModel}));
            changeFn(currentModel, newModel);
            setModel(newModel);
        }
    };

    const updateQty = (e) => {
        const newVal = Number(e.target.value).toFixed(0);
        dispatch(updateAmt({model: currentModel, val: newVal}));
        setQty(newVal);
    };

    const incQty = (e) => {
        const newVal = Number(qty) + 1;
        dispatch(updateAmt({model: currentModel, val: newVal}));
        setQty(newVal);
    };

    const decQty = (e) => {
        const newVal = Math.max(Number(qty) - 1, 0);
        dispatch(updateAmt({model: currentModel, val: newVal}));
        setQty(newVal);
    };

    const showPopover = (e) => {
        if (!popover) {
            setPopover(true);
        }
    };

    const hidePopover = (e) => {
        setPopover(false);
    };

    const toggleAdapterHandler = (e) => {
        const include = e.target.checked;
        dispatch(setAdapter({model: currentModel, include}));
    };

    const ModelSelect = () => {
        return (
            <select id="model"
                    className="block w-full py-2 px-3 mt-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                    name="models"
                    value={currentModel} onChange={changeModel}>
                <option value={currentModel} key={currentModel}>
                    {models[currentModel]}
                </option>
                {availableModels.map((k, i) => {
                    return (<option value={k} key={k}>
                        {models[k]}
                    </option>);
                })}
            </select>
        );
    };

    const ModelSpan = () => {
        return (
            <span className="block w-full py-2 text-xl font-semibold ">
        {models[currentModel]}
      </span>
        );
    };


    const DecButton = () => {
        return <button className="text-white bg-cs-orange border-0 py-2 px-4 rounded-l-lg text-lg" onClick={decQty}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 12H6"/>
            </svg>
        </button>;
    };

    const IncButton = () => {
        return <button className="text-white bg-cs-orange border-0 py-2 px-4 rounded-r-lg text-lg" onClick={incQty}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
            </svg>
        </button>;
    };

    const CloseIcon = () => {
        return <div
            className="w-full flex flex-row-reverse border-gray-50 rounded-t-xl py-2 px-4 bg-gray-100 w-full mt-2 ">
            <button className="cursor-pointer" onClick={deleteSelect}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
                </svg>
            </button>
        </div>;
    };

    const ModelImage = () => {
        return <img className="w-20 h-20 rounded-full bg-indigo-100 text-indigo-500 mb-2 mt-2"
                    alt={currentModel}
                    src={imgMapper[currentModel]}
        />;
    };

    const inputStyle = {
        appearance: 'auto',
        '-webkit-appearance': 'auto',
        '-moz-appearance': 'auto'
    };

    return (
        <>
            <div
                className="relative flex flex-row w-full rounded-lg border-2 border-gray-200 py-2 px-2 md:ml-auto w-full my-2">
                <div className="flex flex-grow ml-2">
                    <div
                        className="w-20 h-20 flex-shrink-0 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-2 mt-2 flex-grow-0"
                        onMouseEnter={showPopover}
                        onMouseLeave={hidePopover}
                    >
                        <ModelImage/>
                    </div>
                    <div
                        className={popover ? "absolute top-0 left-50 bg-gray-100 w-full md:w-full ml-24 -mt-24 border rounded-lg p-4 border-gray-400 z-10 flex flex-row" : "hidden"}
                        onMouseEnter={showPopover}
                        onMouseLeave={hidePopover}
                    >
                        <img
                            className="inline-flex w-1/2 h-1/2 items-center justify-center bg-indigo-100 text-indigo-500 mb-2 mt-2 flex-shrink-0"
                            alt={currentModel}
                            src={imgMapper[currentModel]}
                        />
                        <p className="inline-flex m-4 justify-center">
                            {
                                modelDescriptions[currentModel]
                            }
                        </p>
                    </div>
                    <div className="ml-5 mt-4 flex-item">
                        <label className="text-cs-gray mb-2" htmlFor="model">
                            Cloud PBX Phones
                        </label>
                        <ModelSpan/>
                    </div>
                </div>
                <div className="flex flex-col flex-grow-0">
                    <label className="text-cs-gray mt-2 mb-2" htmlFor="model">
                        Quantity:
                    </label>
                    <div className="flex">
                        <DecButton/>
                        <input type="number"
                               className="border-transparent appearance-none border border-gray-300 w-14 pt-2 pb-2 mb-0 px-2 bg-white text-gray-700 text-base focus:outline-none focus:ring-2 focus:ring-cs-orange focus:border-transparent text-right spin-none"
                               value={Number(qty).toFixed(0)} onChange={updateQty} key={currentModel}/>
                        <IncButton/>
                    </div>
                    <div className="flex-auto flex items-center mt-3">
                        {(['c230', 'c250', 'c270'].includes(currentModel)) &&
                            (
                                <>
                                    <input className="mr-2 flex-0" style={inputStyle} type="checkbox"
                                           defaultChecked={phone.adapter} onClick={toggleAdapterHandler}
                                           id={`${currentModel}-adapter`}/>
                                    <label className="flex-0" htmlFor={`${currentModel}-adapter`}>Include
                                        Adapter</label>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
};
