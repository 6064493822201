import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { calculator } from '../shared/costCalculator';
import { currencyFormat } from '../common/formatters';

const additionalCostDetails = {
  'Base setup': 'Base setup includes:  Initial setup of Cloud server based on your specifications, setup of first (2) phone extensions, setup of (1) DID inbound route, setup of (1) E911 address.',
  "Add'l Phone Setup": 'Base setup includes configuration of (2) phones.  This fee is $50.00 per additional phone beyond the first 2.',
  'Shipping': '1-5 phones = $25.00 shipping, 6+ phones = $50.00 shipping.'
};

const AdditionalCostRow = ({cost, cid}) => {
  const [popover, setPopover] = useState(false);

  const showPopover = (e) => {
    if (!popover) {
      setPopover(true);
    }
  };

  const hidePopover = (e) => {
    setPopover(false);
  };

  return (
    <tr key={cid} className={cid% 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
      <td className="relative px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        <span className="inline"> {cost.name}
        { additionalCostDetails[cost.name] &&
          (
            <>
              <svg xmlns="http://www.w3.org/2000/svg" className="inline h-4 w-4 text-cs-orange ml-2 cursor-pointer" viewBox="0 0 20 20" fill="currentColor"
                onMouseEnter = {showPopover}
                onMouseLeave = {hidePopover}>
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
              </svg>
              <div className={popover ? "absolute top-15 left-10 bg-gray-100 w-full md:w-full ml-20 border rounded-lg p-4 border-gray-400 z-10 whitespace-normal": "hidden"}>
                {additionalCostDetails[cost.name]}
              </div>
            </>
          )}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{currencyFormat(cost.price)}</td>
    </tr>
  )
};

export default function AdditionalCostsTable() {
  const phones = useSelector((state) => state.phone.phones);
  const adapters = useSelector((state) => state.phone.adapters);

  const totalPrices = useMemo(() => {
    return calculator.phoneTotalPrices(phones, adapters);
  }, [phones, adapters]);

  const costs = useMemo(() => {
    return calculator.phoneAdditionalCosts(phones, totalPrices.total);
  }, [totalPrices, phones]);

  const pbxPrice = useMemo(() => {
    let p = 0;
    costs.forEach((c) => {
      p += c.price;
    });
    return p;
  }, [costs])



  return (
    <div className="flex flex-row-reverse">
      <div className="-my-2 ">
        <div className="py-2 align-middle inline-block min-w-full">
          <div className="shadow border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y border divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Item
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Price
                  </th>
                </tr>
              </thead>
              <tbody>
                {costs.filter((c) => {
                  return c.price !== 0;
                }).map((c, cid) => (
                  <AdditionalCostRow key={c.name} cost={c} cid={cid} />
                ))}
              </tbody>
            </table>
          </div>
          <div className="w-full">
            <h2 className="py-4 uppercase my-3 underline text-right text-2xl leading-2 font-semibold text-cs-blue sm:text-2xl"> Total Cloud PBX Price: {currencyFormat(pbxPrice)}</h2>
          </div>
        </div>
      </div>
    </div>
  )
}
