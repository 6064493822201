import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { calculator } from '../shared/costCalculator';
import { currencyFormat } from '../common/formatters';

const costLabels = {
  'users': 'Users',
  'did': "DID's ($1.00/mo)",
  'tollFreeDid': "Toll Free DID's ($1.50/mo)",
  'minutes': 'Minutes (estimate)',
  'tollFreeMin': 'Toll Free Minutes (estimate)'
};

const CloudCostRow = ({cost, id, totalCosts}) => {
  return (
    <tr className={id% 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{costLabels[cost]}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{currencyFormat(totalCosts[cost], 0.01)}</td>
    </tr>
  );
};

export default function AdditionalCostsTable() {
  const phones = useSelector((state) => state.phone.phones);
  const cloud = useSelector((state) => state.cloud);

  const totalUserCount = useMemo(() => {
    return calculator.userCount(phones);
  }, [phones]);

  const totalMrcCosts = useMemo(() => {
    return calculator.mrcTotalPrices(cloud, totalUserCount);
  }, [cloud, totalUserCount]);

  return (
    <div className="flex flex-row-reverse">
      <div className="-my-2 ">
        <div className="py-2 align-middle inline-block min-w-full">
          <div className="shadow border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y border divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Item
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Price
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(costLabels).filter((c) => {
                  return totalMrcCosts[c] !== 0;
                }).map((c, cid) => (
                  <CloudCostRow id={cid} cost={c} totalCosts={totalMrcCosts} key={c} />
                ))}
              </tbody>
            </table>
          </div>
          <div className="w-full">
            <h2 className="py-4 uppercase my-3 underline text-right text-2xl leading-2 font-semibold text-cs-blue sm:text-2xl"> Total Monthly Recurring Charges: {currencyFormat(totalMrcCosts.total, 0.01)}</h2>
            <h4 className="py-4 my-3 text-right text-lg leading-2 text-gray-800 sm:text-lg"> (Plus applicable taxes - typically equal to 15-20% depending on your area)</h4>
          </div>
        </div>
      </div>
    </div>
  )
}
