import React from 'react';
import CloudCostTable from './CloudCostTable';
import CloudInput from './CloudInput';

export default function CloudSection({ show }) {

  return (
    <div className={ show ? "flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2" : "flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2 hidden" }>
      <div className="p-2 md:w-1/2 w-full">
        <div className="rounded p-4 h-full w-full items-center">
          <CloudInput />
        </div>
      </div>
      <div className="p-2 md:w-1/2 w-full">
        <div className="rounded h-full pt-12">
          <CloudCostTable />
        </div>
      </div>
    </div>
  );
}
