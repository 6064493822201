import { createSlice } from '@reduxjs/toolkit'

export const cloudSlice = createSlice({
  name: 'cloud',
  initialState: {
    did: 1,
    tollFreeDid: 0,
    minutes: 1000,
    tollFreeMin: 0
  },
  reducers: {
    updateCloudAmt: (state, action) => {
      const { type, amt } = action.payload;
      state[type] = amt;
      return state
    },
  },
})

export const { updateCloudAmt } = cloudSlice.actions

export default cloudSlice.reducer
