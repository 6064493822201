import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCloudAmt } from './cloudSlice';

const costDetails = {
  'did': 'Enter the total number of DID phone numbers you would like to purchase or port to the Crosstalk Cloud solution.  (1) DID phone number is included in the base cost.  Additional DID phone numbers are $1.00/mo.',
  'tollFreeDid': 'Enter the total number of Toll Free phone numbers you would like to purchase or port to the Crosstalk Cloud solution.  Toll Free phone numbers are $1.50/mo.'
};

export default function CloudInput() {
  const cloud = useSelector((state) => state.cloud);
  const dispatch = useDispatch({});
  const [didPopover, setDidPopover] = useState(false);
  const [tollPopover, setTollPopover] = useState(false);

  const [amtOnChange, setAmtOnChange] = useState({
    did: () => {},
    tollFreeDid: () => {},
    minutes: () => {},
    tollFreeMin: () => {}
  });

  useEffect(() => {
    const amtTypes = ['did', 'tollFreeDid', 'minutes', 'tollFreeMin'];
    const onChanges = {}
    amtTypes.forEach((type) => {
      onChanges[type] = (e) => {
        const amt = Number(e?.target?.value);
        dispatch(updateCloudAmt({ type, amt }));
      };
    });
    setAmtOnChange(onChanges)
  }, [cloud, dispatch]);

  const showDidPopover = (e) => {
    if (!didPopover) {
      setDidPopover(true);
    }
  };

  const hideDidPopover = (e) => {
    setDidPopover(false);
  };

  const showTollPopover = (e) => {
    if (!tollPopover) {
      setTollPopover(true);
    }
  };

  const hideTollPopover = (e) => {
    setTollPopover(false);
  };

  return (
    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
      <div>
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">DIDs</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Your Local and Toll Free phone numbers.</p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="relative sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                DID's ($1.00/mo)
                <svg xmlns="http://www.w3.org/2000/svg" className="inline h-4 w-4 text-cs-orange ml-2 cursor-pointer" viewBox="0 0 20 20" fill="currentColor"
                  onMouseEnter = {showDidPopover}
                  onMouseLeave = {hideDidPopover}>
                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                </svg>
                <div className={didPopover ? "absolute top-15 left-10 bg-gray-100 w-full md:w-full ml-20 border rounded-lg p-4 border-gray-400 z-10 whitespace-normal": "hidden"}>
                  {costDetails['did']}
                </div>
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="number"
                  id="did"
                  className="max-w-lg block w-full shadow-sm focus:ring-cs-orange focus:border-cs-orange sm:max-w-xs sm:text-sm border-gray-300 rounded-md spin-none"
                  value={Number(cloud.did).toFixed(0)}
                  onChange={amtOnChange['did']}
                />
              </div>
            </div>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="relative sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 -mr-4">
                Toll Free DID's ($1.50/mo)
                <svg xmlns="http://www.w3.org/2000/svg" className="inline h-4 w-4 text-cs-orange ml-2 cursor-pointer" viewBox="0 0 20 20" fill="currentColor"
                  onMouseEnter = {showTollPopover}
                  onMouseLeave = {hideTollPopover}>
                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                </svg>
                <div className={tollPopover ? "absolute top-15 left-10 bg-gray-100 w-full md:w-full ml-20 border rounded-lg p-4 border-gray-400 z-10 whitespace-normal": "hidden"}>
                  {costDetails['tollFreeDid']}
                </div>
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="number"
                  id="did"
                  className="max-w-lg block w-full shadow-sm focus:ring-cs-orange focus:border-cs-orange sm:max-w-xs sm:text-sm border-gray-300 rounded-md spin-none"
                  value={Number(cloud.tollFreeDid).toFixed(0)}
                  onChange={amtOnChange['tollFreeDid']}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Minutes</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Your estimated monthly minutes.</p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Minutes (estimate)
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="number"
                  id="did"
                  className="max-w-lg block w-full shadow-sm focus:ring-cs-orange focus:border-cs-orange sm:max-w-xs sm:text-sm border-gray-300 rounded-md spin-none"
                  value={Number(cloud.minutes).toFixed(0)}
                  onChange={amtOnChange['minutes']}
                />
              </div>
            </div>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Toll Free Minutes (estimate)
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="number"
                  id="did"
                  className="max-w-lg block w-full shadow-sm focus:ring-cs-orange focus:border-cs-orange sm:max-w-xs sm:text-sm border-gray-300 rounded-md spin-none"
                  value={Number(cloud.tollFreeMin).toFixed(0)}
                  onChange={amtOnChange['tollFreeMin']}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
