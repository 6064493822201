import React, { useState } from 'react';
import PhoneSection from './phones/PhoneSection';
import CloudSection from './cloud/CloudSection';
import updateContactForm from './contact/updateContactForm';
import { useSelector } from 'react-redux';

function Calculator() {
  const tabs = {
    upfront: {
      name: 'Upfront Costs',
    },
    mrc: {
      name: 'Monthly Costs',
    }
  };

  const [activeTab, setActiveTab] = useState('upfront');

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const toggleTab = (e) => {
    const tabVal = e.target.getAttribute('val');
    setActiveTab(tabVal)
    e.preventDefault();
  }

  const phones = useSelector((state) => state.phone.phones);
  const adapters = useSelector((state) => state.phone.adapters);
  const cloud = useSelector((state) => state.cloud);

  updateContactForm({
    phones,
    adapters,
    cloud
  });

  return (
    <section className="text-gray-600 bg-cs-bg-gray-50 body-font">
      <div className="pt-2 border-cs-orange border-b-2">
        <div className="w-full lg:w-10/12 mx-auto">
          <div className="sm:flex sm:items-baseline">
            <h3 className="text-2xl leading-6 font-medium text-cs-blue">Cloud PBX</h3>
            <div className="mt-4 sm:mt-0 sm:ml-10">
              <nav className="-mb-px flex space-x-8">
                {Object.keys(tabs).map((t) => (
                  <button
                    key={tabs[t].name}
                    onClick={toggleTab}
                    className={classNames(
                      t === activeTab
                        ? 'border-cs-orange bg-cs-orange text-gray-50'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                      'whitespace-nowrap px-1 font-medium text-xl p-4 rounded-t-lg'
                    )}
                    val={t}
                    aria-current={t === activeTab? 'page' : undefined}
                  >
                    {tabs[t].name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto">
        <PhoneSection show={activeTab === 'upfront'} />
      </div>
      <div className="mx-auto">
        <CloudSection show={activeTab === 'mrc'} />
      </div>
    </section>
  );
}

export default Calculator;
