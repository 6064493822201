import React from 'react';
import { useSelector } from 'react-redux';
import { calculator, phonePrices, adapterPrice } from '../shared/costCalculator';
import { currencyFormat } from '../common/formatters';

export default function PhonesTable() {
  const phones = useSelector((state) => state.phone.phones);
  const adapters = useSelector((state) => state.phone.adapters);

  const models = {
    'c230': 'Crosstalk 230',
    'c250': 'Crosstalk 250',
    'c270': 'Crosstalk 270',
    'sm100m10': 'Snom M100 KLE + M10 KLE (1 standard handset + DECT base)',
    'sm100m10r': 'Snom M100 KLE + M10R KLE (1 ruggedized handset + DECT base)',
    'sm10': 'Snom M10 KLE (Standard DECT handset only)',
    'sm10r': 'Snom M10R KLE (Ruggedized DECT handset only)',
    'sc520': 'Snom C520 Conference Phone',
    'soft': 'Softphone Only User'
  };


  const totalPrices = calculator.phoneTotalPrices(phones, adapters);


  const phonesList = ['c230', 'c250', 'c270', 'sm100m10', 'sm100m10r', 'sm10', 'sm10r', 'sc520', 'soft'];

  return (
    <div className="flex flex-row-reverse">
      <div className="py-2 align-middle inline-block min-w-full ">
        <div className="shadow sm:rounded-lg">
          <table className="min-w-full divide-y border border-gray-200 divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Phone Model
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                Qty
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Price
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Total Price
                </th>
              </tr>
            </thead>
            <tbody>
              {phonesList.filter((p) => {
                return phones[p] > 0;
              }).map((p, pid) => (
                <tr key={p} className={pid% 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{models[p]}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{phones[p]}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{currencyFormat(phonePrices[p] + ((adapters[p] || 0) * adapterPrice))}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-40">{currencyFormat(totalPrices[p])}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="w-full">
          <h2 className="py-4 mt-3 uppercase underline text-right text-2xl leading-2 font-semibold text-cs-blue sm:text-2xl"> Total Phone price: {currencyFormat(totalPrices.total)}</h2>
        </div>
      </div>
    </div>
  )
}
