import React, {useState, useEffect} from 'react';
import {addPhone, removePhone, updateQty} from './phonesSlice';
import {useSelector, useDispatch} from 'react-redux';
import PhoneSelect from './PhoneSelect';

export default function PhonesSelection() {
    const [phones, setPhoneState] = useState([
        {
            model: 'c230',
            qty: 0,
            adapter: false
        },
        {
            model: 'c250',
            qty: 0,
            adapter: false
        },
        {
            model: 'c270',
            qty: 2,
            adapter: false
        },
        {
            model: 'sm100m10',
            qty: 0,
            adapter: false
        },
        {
            model: 'sm100m10r',
            qty: 0,
            adapter: false
        },
        {
            model: 'sm10',
            qty: 0,
            adapter: false
        }, {
            model: 'sm10r',
            qty: 0,
            adapter: false
        }, {
            model: 'sc520',
            qty: 0,
            adapter: false
        },
        {
            model: 'soft',
            qty: 0,
            adapter: false
        }
    ]);
    const [canAdd, setCanAdd] = useState(true);
    const allPhonesList = ['c230', 'c250', 'c270', 'sm100m10', 'sm100m10r', 'sm10', 'sm10r', 'sc520', 'soft'];
    const [availableModels, setAvailableModels] = useState(allPhonesList);
    const phonesState = useSelector((state) => state.phone.phones);
    const adapterState = useSelector((state) => state.phone.adapters);

    useEffect(() => {
        const newState = phones.map((p) => {
            return {
                model: p.model,
                qty: phonesState[p.model],
                adapter: adapterState[p.model]
            };
        });
        setPhoneState(newState);
    }, [phonesState]);

    const isPhoneInState = (model, allPhoneModels) => {
        let inState = false;
        allPhoneModels.forEach(p => {
            if (p.model === model) {
                inState = true;
            }
        });
        return inState;
    };

    const getNextModel = (allPhones) => {
        const possibleValues = [];

        allPhonesList.forEach((m) => {
            if (!isPhoneInState(m, allPhones)) {
                possibleValues.push(m);
            }
        });

        if (possibleValues.length === 0) {
            setCanAdd(false);
        } else {
            return possibleValues[0];
        }
    };

    const updateAvailableModels = (state) => {
        const availPhones = [];
        allPhonesList.forEach((m) => {
            if (!isPhoneInState(m, state)) {
                availPhones.push(m);
            }
        });
        setAvailableModels(availPhones)
    };

    const addPhoneSection = () => {
        const newPhone = {
            model: getNextModel(phones),
            qty: 0
        };
        const newState = [...phones, newPhone];
        setPhoneState([...phones, newPhone]);
        // use sideffect to set button state
        const nextPhone = getNextModel(newState);
        updateAvailableModels(newState);
    };

    const deletePhone = (model) => {
        // Fire Redux State Change
        const newState = phones.filter((p) => {
            return p.model !== model;
        });
        setPhoneState(newState);
        updateAvailableModels(newState);
        setCanAdd(true);
    };

    const changeModel = (oldModel, newModel) => {
        const newState = phones.map((p) => {
            if (p.model === oldModel) {
                return {
                    model: newModel,
                    qty: p.qty
                };
            } else {
                return p;
            }
        });
        setPhoneState(newState);
        updateAvailableModels(newState);
    };

    // Initial available model update to remove c250
    useEffect(() => {
        updateAvailableModels(phones)
    }, []);

    return (
        <>
            <div className="w-full">
                <label className="text-cs-blue uppercase text-lg font-semibold title-font mb-4">Select Your Phones
                    (Mouseover Pics for Detail): </label>
                {phones.map((p) => {
                    return <PhoneSelect phone={p} availableModels={availableModels} key={p.model} deleteFn={deletePhone}
                                        changeFn={changeModel}/>
                })}
            </div>
        </>
    );
}
