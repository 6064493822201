import { configureStore } from '@reduxjs/toolkit';
import phoneReducer from './phones/phonesSlice';
import cloudReducer from './cloud/cloudSlice';

export default configureStore({
  reducer: {
    phone: phoneReducer,
    cloud : cloudReducer
  },
})
