export const phonePrices = {
    'c230': 115,
    'c250': 149,
    'c270': 199,
    'sm100m10': 188,
    'sm100m10r': 288,
    'sm10': 99,
    'sm10r': 189,
    'sc520': 345,
    'soft': 0
};

export const adapterPrice = 6.5;

const phoneCount = (phones) => {
    let count = 0;
    Object.values(phones).forEach((c) => {
        count += Number(c);
    });
    return count;
};

const physicalPhoneCount = (phones) => {
    let count = 0;
    Object.keys(phones).forEach((p) => {
        if (p !== 'soft') { //softphones do not require shipping
            count += Number(phones[p]);
        }
    });
    return count;
}

export const mrcPrices = {
    did: 100,
    tollFreeDid: 150,
    minutes: 0.9,
    tollFreeMin: 2.1
};

export const calculator = {
    phoneTotalPrices: (phones, adapters) => {
        let total = 0;
        const totalPrices = {};
        Object.keys(phones).forEach((p) => {
            const unitPrice = phonePrices[p] + (adapters[p] ? 1 : 0) * adapterPrice;
            const phoneCost = phones[p] * unitPrice;
            totalPrices[p] = phoneCost;
            total += phoneCost;
        });
        totalPrices.total = total;
        return totalPrices
    },
    userCount: phoneCount,

    mrcTotalPrices: (cloud, userCount) => {
        let total = 0;
        const totalPrices = {};
        totalPrices.users = userCount * 500;
        total += totalPrices.users;
        Object.keys(cloud).forEach((c) => {
            let mrcPrice;
            // first did line is free
            if (c === 'did') {
                mrcPrice = Math.max(0, cloud[c] - 1) * mrcPrices[c];
            } else {
                mrcPrice = cloud[c] * mrcPrices[c];
            }
            totalPrices[c] = mrcPrice;
            total += mrcPrice;
        });
        totalPrices.total = total;
        return totalPrices
    },

    phoneAdditionalCosts: (phones, total) => {
        const phonesCount = phoneCount(phones);
        const physicalCount = physicalPhoneCount(phones);
        // 0 if no phones, else 25 if 1-5 phones, else 50
        const shippingCost = physicalCount === 0 ? 0 : (physicalCount <= 5 ? 25 : 50);
        return [
            {name: 'Base setup', price: 250},
            {name: 'Phones', price: total},
            {name: "Add'l Phone Setup", price: Math.max(phonesCount - 2, 0) * 50},
            {name: 'Extras', price: 0},
            {name: 'Shipping', price: shippingCost},
        ];
    }
}

export default calculator
