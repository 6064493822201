import PhonesTable from './PhonesTable';
import PhonesSelection from './PhonesSelection';
import AdditionalCostsTable from './AdditionalCostsTable';

export default function PhoneSection({ show }) {

  return (
    <div className={ show ? "flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2" : "flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2 hidden" }>
      <div className="p-2 md:w-1/2 w-full">
        <div className="rounded p-4 h-full w-full items-center">
          <PhonesSelection />
        </div>
      </div>
      <div className="p-2 md:w-1/2 w-full">
        <div className="rounded h-full pt-12
        ">
          <PhonesTable />
          <AdditionalCostsTable />
        </div>
      </div>
    </div>
  );
}
