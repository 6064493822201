export default function updateContactForm(state) {
  const EL_ID = 'calc-state';
  const formArea = document.getElementById(EL_ID);
  if (formArea) {
    // hide form area
    const formStyle = window.getComputedStyle(formArea);
    const formDisplay = formStyle.getPropertyValue('display');
    if (formDisplay !== 'none') {
      formArea.style.display = 'none';
    }
    // SetState to calculator state
    formArea.innerText = JSON.stringify(state);
  };
}
