const formatter = {
  currencyFormat: (n, u=1) => {
    const init = Number.parseFloat(n);
    const withUnit = init * u;
    return `$${withUnit.toFixed(2)}`;
  }
};

export default formatter;
export const { currencyFormat } = formatter;
